export const messages = {
  'pt-BR': {
    translations: {
      app: 'Insight Sales',
      welcome: 'Bem-vindo ao InsightSales',
      fullScreen: 'Entrar no modo tela cheia',
      productivityDashboard: 'Dashboard de Produtividade',
      selectTheFunnelsYouWantToReceiveEmailNotificationsFor:
        'Selecione os funis que deseja receber notificações por e-mail',
      selectTheFunnelsYouWantToReceiveSlackNotificationsFor:
        'Selecione os funis que deseja receber notificações via Slack',
      clickToSelectFunnels: 'Clique para selecionar os funis',
      noItemsSelected: 'Nenhum item selecionado',
      lostAnalysis: 'Análise de Lost',
      setTimeZoneAndCurrency: 'Definir fuso horário e moeda',
      exportPeriod1Data: 'Exportar dados do período 1',
      exportPeriod2Data: 'Exportar dados do período 2',
      Master: 'Master',
      'Select user permission': 'Selecione a permissão do usuário',
      'Commercial manager': 'Gerente comercial',
      Seller: 'Vendedor(a)',
      MASTER: 'Master',
      COMMERCIAL_MANAGER: 'Gerente comercial',
      SELLER: 'Vendedor(a)',
      'User permission': 'Permissão do usuário',
      country: 'País',
      Login: 'Login',
      openedIn: 'Aberto em',
      lostIn: 'Perdido em',
      leadIn: 'Lead em',
      leadResponsibles: 'Responsáveis do lead',
      mqlIn: 'MQL em',
      mqlResponsibles: 'Responsáveis do MQL',
      sqlIn: 'SQL em',
      sqlResponsibles: 'Responsáveis do SQL',
      opportunityIn: 'Opportunity em',
      opportunityResponsibles: 'Responsável da oportunidade',
      dealIn: 'Deal em',
      dealResponsibles: 'Responsáveis do Deal',
      zone: 'Zona',
      currency: 'Moeda',
      continueWithGoogle: 'Continuar com o Google',
      continueWithMicrosoft: 'Continuar com a Microsoft',
      appSuccessfullyUpdated: 'APP atualizado com sucesso',
      slack: 'Slack',
      definitions: 'Definições',
      continent: 'Continente',
      region: 'Região',
      notifications: 'Notificações',
      efforts: 'Esforços',
      goals: 'Metas',
      teams: 'Equipes',
      funnels: 'Funis',
      profile: 'Perfil',
      leads: 'Leads',
      connectedTo: 'Conectado ao',
      connectTo: 'Conectar ao',
      lead: 'Lead',
      myProfile: 'Meu Perfil',
      signOut: 'Sair',
      settings: 'Configurações',
      mySettings: 'Minhas Configurações',
      closeMenu: 'Fechar Menu',
      close: 'Fechar',
      currencyAcronym: 'R$',
      comparePeriods: 'Comparar períodos',
      compareFunnels: 'Comparar funis',
      compareMembers: 'Comparar membros',
      noLostFunnel: 'Funil sem losts',
      leadsWithLost: 'Leads com lost',
      deal: 'Deal',
      weekly: 'Semanal',
      monthly: 'Mensal',
      week: 'Semana',
      unlimited: 'Sem limites',
      allMembers: 'Filtro de membro (todos)',
      filterByTeamAll: 'Filtro por equipe (todos)',
      filterByStageAll: 'Filtro de etapa (todos)',
      leadsWasImportedToTheFunnel: 'lead(s) foram importados para o funil',
      importedLeads: 'Importado(s): {{importedLeads}} lead(s)',
      exportCsvData: 'Exportar dados CSV',
      all: 'Todos',
      selectAFunnel: 'Selecione um funil',
      selectAMember: 'Selecione um membro',
      funnelWithoutRegistration: 'Nenhum funil cadastrado',
      dateFilterOff: 'Filtro de data (desligado)',
      today: 'Hoje',
      yesterday: 'Ontem',
      thisWeek: 'Essa semana',
      lastWeek: 'Semana passada',
      thisMonth: 'Esse mês',
      lastMonth: 'Último mês',
      thisQuarter: 'Esse trimestre',
      lastTrimester: 'Último trimestre',
      thisYear: 'Esse ano',
      lastYear: 'Último ano',
      raceDate: 'Data corrida',
      customDateRange: 'Intervalo de datas customizado',
      last7days: 'Últimos 7 dias',
      last14days: 'Últimos 14 dias',
      last30days: 'Últimos 30 dias',
      last60days: 'Últimos 60 dias',
      last90days: 'Últimos 90 dias',
      last365days: 'Últimos 365 dias',
      initialData: 'Data Inicial',
      finalDate: 'Data Final',
      selectAnImage: 'Selecione uma imagem',
      selectAnIntegrationType: 'Selecione um tipo de integração',
      new: 'Novo',
      saving: 'Salvando...',
      toSave: 'Salvar',
      normal: 'Normal',
      addInBatch: 'Adicionar em lote',
      template: 'template',
      fillInThe: 'Preencha o',
      selectFile: 'Selecionar arquivo',
      choose: 'Escolher',
      preSales: 'Pré-vendas',
      sales: 'Vendas',
      'pré-vendas': 'Pré-vendas',
      vendas: 'Vendas',
      theFileMustBeInCSVFormat: 'O arquivo deve ser no formato CSV',
      exportInCSVAndAddBelow: 'exporte em csv e adicione abaixo',
      addMember: 'Adicionar membro',
      editMember: 'Editar membro',
      memberName: 'Nome do membro',
      memberEmail: 'E-mail do membro',
      office: 'Cargo',
      editTeam: 'Editar Equipe',
      newTeam: 'Nova Equipe',
      teamName: 'Nome da equipe',
      nameOfResponsible: 'Nome do responsável',
      responsibleEmail: 'E-mail do responsável',
      notes: 'Notas',
      meeting: 'Reuniões',
      call: 'Ligações',
      email: 'E-Mail',
      password: 'Senha',
      forcePassword: 'Esqueci minha senha',
      access: 'Acessar',
      signUpForInsightSales: 'Cadastre-se no InsightSales',
      accessMyAccount: 'Acessar minha conta',
      register: 'Cadastrar',
      company: 'Empresa',
      name: 'Nome',
      redefinePassword: 'Redefinir senha',
      toSend: 'Enviar',
      change: 'Alterar',
      add: 'Adicionar',
      profilePicture: 'foto do perfil',
      chooseAnImageThatIs: 'Escolha uma imagem que seja',
      or: 'ou',
      occupation: 'Função',
      saveEditions: 'Salvar Alterações',
      confirmThePassword: 'Confirme a senha',
      newPassword: 'Nova senha',
      currentPassword: 'Senha atual',
      changePassword: 'Alterar senha',
      passwordChangedSuccessfully: 'Senha alterada com sucesso!',
      definitionsWereUpdatedSuccessfully: 'As definições foram atualizadas com sucesso!',
      telephone: 'Telefone',
      personalData: 'Dados pessoais',
      tokenAPI: 'Token API',
      generateToken: 'Gerar Token',
      changeToken: 'Trocar Token',
      addIntegration: 'Adicionar Integração',
      integration: 'Integração',
      integrations: 'Integrações',
      considerFrozen: 'Considerar congelados',
      editIntegration: 'Editar Integração',
      newIntegration: 'Nova Integração',
      options: 'Opções',
      importOldData: 'Importar dados antigos',
      integrationAccount: 'Conta da Integração',
      funnel: 'Funil',
      status: 'Status',
      team: 'Equipe',
      crm: 'CRM',
      funnelInCRM: 'Funil no CRM',
      integrationFunnel: 'Funil da Integração',
      stages: 'Estágios',
      line: 'Linha',
      comeBack: 'Voltar',
      tryAgain: 'Tentar novamente',
      integrateAccounts: 'Integrar contas',
      addAccount: 'Adicionar Conta',
      toConnect: 'Conectar',
      totalGoal: 'Meta total',
      goal: 'Meta',
      leadRecipe: 'Receita Lead',
      MQLRecipe: 'Receita MQL',
      SQLRecipe: 'Receita SQL',
      opportunityRevenue: 'Receita oportunidade',
      newCustomersRevenue: 'Receita novos clientes',
      updated: 'Atualizado',
      in: 'de',
      at: 'às',
      exitFullScreenMode: 'Sair do modo tela cheia',
      reached: 'Alcançado',
      conversion: 'CONVERSÃO',
      opportunities: 'Oportunidades',
      customers: 'Clientes',
      client: 'Cliente',
      mql: 'MQL',
      sql: 'SQL',
      lost: 'Perdido',
      opportunity: 'Oportunidade',
      areNotValidEmails: 'não são e-mail(s) validos',
      appIntegrationSuccessfullyDeleted: 'Integração com app deletada com sucesso',
      turnOnNotificationsToUpdateEmails: 'Ative as notificações para atualizar os e-mails',
      enterTheEmailsYouWantToReceiveNotificationsSeparatedByCommas:
        'Digite os e-mails que deseja receber as notificações separados por vírgula...',
      churn: 'Churn',
      accountName: 'Nome da conta (url name)',
      newCustomers: 'Novos Clientes',
      totalAverageTicket: 'Ticket médio total',
      averageMRRTicket: 'Ticket médio MRR',
      lostRevenueInMQL: 'Receita Perdida em MQL',
      leadOpenRevenue: 'Receita em aberto Lead',
      MQLOpenRevenue: 'Receita em aberto MQL',
      lostRevenueInSQL: 'Receita Perdida em SQL',
      openSQLRecipe: 'Receita em aberto SQL',
      lostRevenueInOpportunity: 'Receita Perdida em Oportunidade',
      openRevenueOpportunity: 'Receita em aberto Oportunidade',
      MRRRevenue: 'Receita MRR',
      salesCycle: 'Ciclo de Vendas',
      deleteTeam: 'Tem certeza que deseja deletar essa equipe?',
      deleteMember: 'Tem certeza que deseja deletar esse membro?',
      memberSuccessfullyChanged: 'Membro alterado com sucesso!',
      successfullyCreatedMembers: 'Membros criados com sucesso!',
      addMoreThan5members: 'Para adicionar mais do que 5 membros, vá na função',
      successfullyEditedTeam: 'Equipe editada com sucesso!',
      newTeamSuccessfullyAdded: 'Nova equipe adicionada com sucesso!',
      passwordResetSuccessfully: 'Senha redefinida com sucesso!',
      theUploadedImageIsTooSmallMustHaveAtLeast: 'A imagem carregada é muito pequena. Deve ter pelo menos',
      theUploadedImageIsTooBigShouldntHaveMoreTo: 'A imagem carregada é muito grande. Não deve ter mais que',
      thereWasAProblemLoadingTheImage: 'Ocorreu um problema ao carregar a imagem',
      youNeedToUploadAFileOfType: 'É necessário fazer upload de um arquivo do tipo',
      theFileSizeMustBeLessThan0Score5MB: 'O tamanho do arquivo deve ser menor que 0,5 MB.',
      profileSuccessfullyChanged: 'Perfil alterado com sucesso!',
      areYouSureYouWantToDeleteThisIntegration: 'Tem certeza que deseja deletar essa integração?',
      chooseAnIntegrationType: 'Escolha um tipo de integração.',
      chooseAFunnel: 'Escolha um funil.',
      chooseAnIntegrationFunnel: 'Escolha um funil da integração.',
      chooseAtLeastOneStageOf: 'Escolha ao menos um estágio de',
      integrationUpdatedSuccessfully: 'Integração atualizada com sucesso!',
      integrationCreatedSuccessfully: 'Integração criada com sucesso!',
      integrationPerformedSuccessfully: 'Integração realizada com sucesso.',
      itWasNotPossibleToPerformTheIntegrationTryAgainLater:
        'Não foi possível realizar a integração. Tente novamente mais tarde.',
      areYouSureYouWantToDeleteThisAccount: 'Tem certeza que deseja deletar essa conta?',
      successfullyAddedGoal: 'Meta adicionada com sucesso!',
      targetUpdatedSuccessfully: 'Meta atualizada com sucesso!',
      wantToSaveWithoutSelectingAllMembers: 'Deseja salvar sem selecionar todos os membros?',
      areYouSureYouWantToDeleteThisFunnel: 'Tem certeza que deseja deletar esse funil?',
      cancel: 'Cancelar',
      editOpportunity: 'Editar Oportunidade',
      newOpportunity: 'Nova Oportunidade',
      opportunitySuccessfullyEdited: 'Oportunidade editada com sucesso!',
      newOpportunitySuccessfullyAdded: 'Nova oportunidade adicionada com sucesso!',
      companyCannotBeBlank: 'Empresa não pode ficar em branco',
      addAtLeast1Responsible: 'Adicione pelo menos 1 responsável',
      fillInThoseResponsible: 'Preencha os responsáveis',
      addAtLeast1PersonResponsiblePerInternship: 'Adicione pelo menos 1 responsável por estágio',
      responsibles: 'Responsáveis',
      value: 'Valor',
      addOpportunity: 'Adicionar oportunidade',
      youWillReceivePasswordResetInstructionsInYourEmail:
        'Você receberá instruções para a redefinição de senha no seu e-mail.',
      opsThereWasAProblem: 'Ops! Ocorreu um problema.',
      sessionExpired: 'Sessão expirada',
      updateEvery5minutes: 'Atualizar a cada (5 minutos)',
      minutes10: '10 minutos',
      minutes30: '30 minutos',
      connectedAccounts: 'Contas Conectadas',
      selectAnOption: 'Selecione uma opção',
      selectAFilter: 'Selecione um filtro',
      yes: 'Sim',
      not: 'Não',
      openMenu: 'Abrir Menu',
      dashboards: 'Dashboards',
      period: 'Período',
      period1: 'Período 1',
      period2: 'Período 2',
      Title: 'Título',
      Goal: 'Meta',
      Reach: 'Alcance',
      Conquest: 'Conquista',
      Indicator: 'Indicador',
      opportunitySuccessfullyRemoved: 'Oportunidade removida com sucesso!',
      enterYourSearchHere: 'Digite aqui a sua busca',
      cardLimit20: 'Limite de cards (20)',
      cards50: '50 cards',
      cards100: '100 cards',
      'User created successfully': 'Usuário criado com sucesso',
      'User updated successfully': 'Usuário atualizado com sucesso',
      'User successfully deleted': 'Usuário deletado com sucesso',
      ok: 'OK',
      Users: 'Usuários',
      Close: 'Fechar',
      Copied: 'Copiado',
      Create: 'Criar',
      Update: 'Atualizar',
      noIntegration: 'Sem integração',
      selectATeam: 'Selecione um time',
      inactive: 'Inativo',
      language: 'Idioma',
      'New user': 'Novo usuário',
      Cancel: 'Cancelar',
      Optional: 'Opcional',
      selectAZone: 'Selecione uma zona',
      selectARegion: 'Selecione uma região',
      selectACurrency: 'Selecione uma moeda',
      selectYourLanguage: 'Selecione seu idioma',
      active: 'Ativo',
      typeHere: 'Digite aqui',
      funnelSuccessfullyChanged: 'Funil alterado com sucesso!',
      funnelCreatedSuccessfully: 'Funil criado com sucesso!',
      toEdit: 'Editar',
      delete: 'Deletar',
      opsSomethingWrong: 'Ops! Alguma coisa deu errado...',
      criticalErrorMessagePart1: 'Sentimos muito pelo inconveniente. Por favor, entre em contato com a equipe',
      criticalErrorMessagePart2: 'para que possamos ajudar',
      goToHomePage: 'Ir para a página inicial',
      yourPersonalAPIToken: 'Seu token de API pessoal',
      members: 'Membros',
      member: 'Membro',
      openingDate: 'Data de abertura',
      nowAllYouHaveToDoIsSendYourAPIToken: 'Falta pouco: Agora só falta enviar seu token API do',
      editToken: 'Editar Token',
      deleteAccount: 'Deletar conta',
      tokenNotTested: 'Token não testado',
      registeredToken: 'Token cadastrado',
      tokenNotSentOrInvalid: 'Token não enviado ou invalido',
      testAPIToken: 'Testar API Token',
      INTERNAL_SERVER_ERROR: 'Erro interno do servidor.',
      UNABLE_TO_CREATE_A_GOAL_FOR_PAST_MONTHS: 'Não é possível criar uma meta para meses passados',
      MEMBER_STAGES_ARE_INVALID: 'Os estágios do membro estão inválidos',
      FUNNEL_NOT_FOUND: 'Funil não encontrado.',
      TARGET_ALREADY_EXISTS_FOR_THIS_FUNNEL: 'Meta já existe para esse funil',
      MEMBER_NOT_FOUND: 'Membro não encontrado',
      STAGES_CANNOT_BE_EMPTY: 'Os estágios não podem ser vazios',
      STAGES_MUST_BE_MQL_SQL_OPPORTUNITY_OR_CUSTOMERS: 'Os estágios devem ser MQL, SQL, Oportunidade ou Novos Clientes',
      THE_STAGES_ARE_INVALID: 'Os estágios estão inválidos',
      INVALID_ARGUMENT_TYPE: 'Tipo de argumento inválido.',
      INVALID_OBJECT_TYPE: 'Tipo de objeto inválido.',
      NO_ACCESS_TOKEN_PROVIDED: 'Nenhum token de acesso fornecido',
      THE_TOKEN_SIGNATURE_HAS_EXPIRED: 'A assinatura do token expirou.',
      THIS_EMAIL_ALREADY_HAS_AN_ACCOUNT: 'Este email já possui conta.',
      EMAIL_NOT_ENTERED: 'Email não informado.',
      DISABLED_COMPANY_IN_CASE_OF_DOUBT_CONTACT_OUR_SUPPORT:
        'Empresa desabilitada, em caso de dúvida entre em contato com o nosso suporte.',
      INCORRECT_EMAIL_OR_PASSWORD: 'Email ou senha incorretos.',
      PASSWORD_AND_CONFIRMATION_PASSWORD_DO_NOT_MATCH: 'A senha e a senha de confirmação não são iguais.',
      USER_NOT_FOUND: 'Usuário não encontrado.',
      INCORRECT_CURRENT_PASSWORD: 'Senha atual incorreta.',
      EMAIL_IS_REQUIRED: 'Email é obrigatório.',
      NO_USERS_FOUND_WITH_THIS_EMAIL_ADDRESS: 'Nenhum usuário encontrado com esse endereço de email.',
      NO_USERS_FOUND: 'Nenhum usuário encontrado.',
      INVALID_TOKEN: 'Token inválido.',
      INTEGRATION_NOT_FOUND: 'Integração não encontrada.',
      THE_STAGES_MUST_BE: 'Os estágios devem ser',
      OR: 'ou',
      THE_STAGES_MUST_BE_NAMES_OR_NAMES: '$t(THE_STAGES_MUST_BE) {{orJoin}}',
      INTEGRATION_ALREADY_EXISTS: 'Integração já existe.',
      ACCOUNT_NOT_FOUND: 'Conta não encontrada.',
      INTEGRATION_FUNNELS_ARE_DUPLICATED: 'Os funis da integração estão duplicados!',
      LINE: 'Linha',
      LINE_N_FUNNEL_NOT_FOUND: '$t(LINE) {{count}} - $t(FUNNEL_NOT_FOUND)',
      LINE_N_INTEGRATION_ALREADY_EXISTS: '$t(LINE) {{line}} - $t(INTEGRATION_ALREADY_EXISTS)',
      LINE_N_INTEGRATION_ALREADY_EXISTS_STAGE_VALIDATION:
        '$t(LINE) {{line}} - $t(INTEGRATION_ALREADY_EXISTS) {{orJoin}}',
      YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN: 'Você atingiu o limite no plano contratado.',
      YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN_TO_ADD_MORE_SELLERS_CONTACT_OUR_CS_TEAM_IN_CHAT:
        '$t(YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN) Para adicionar mais vendedores, entre em contato no chat com nossa equipe de CS.',
      YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN_To_ADD_MORE_FUNNELS_PLEASE_CHAT_WITH_OUR_CSS_TEAM:
        '$t(YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN) Para adicionar mais funis, entre em contato no chat com nossa equipe de CS.',
      TEAM_NOT_FOUND: 'Time não encontrado',
      LINE_TEAM_NOT_FOUND: 'Time não encontrado',
      INVALID_INTEGRATION_TYPE: 'Tipo de integração inválida.',
      API_TOKEN_IS_INVALID: 'API Token é inválido!',
      GOAL_NOT_FOUND: 'Meta não encontrada',
      THE_STEP_MUST_BE_EQUAL_TO_MQL_SQL_OPPORTUNITY_OR_DEAL:
        'O passo deve ser igual a `mql`,` sql`, `oportunidade` ou` clientes`',
      THE_AT_IS_NOT_A_VALID_DATE: 'O `at` não é uma data válida',
      OPPORTUNITY_NOT_FOUND: 'Oportunidade não encontrada',
      SIGNATURE_NOT_FOUND: 'Assinatura não encontrada.',
      SIGNATURE_VERSION_IS_INVALID: 'A versão da assinatura é inválida.',
      UNAUTHORIZED: 'Não autorizado',
      TEAM_MEMBER_NOT_FOUND: 'Membro da equipe não encontrado',
      INVALID_CONNECTION_TYPE: 'Tipo de conexão inválida.',
      FILL_IN_THE_REQUIRED_FIELDS: 'Preencha os campos obrigatórios.',
      INVALID_STEP_PROVIDED_MUST_BE_MQL_SQL_OR_DEAL: 'Etapa inválida fornecida, deve ser mql, sql ou deal.',
      THE_FUNNEL_LEAD_IS_ALREADY_IN_THIS_STEP: 'O lead do funil já está nesta etapa.',
      FIELDS_REQUIRED_WAS_NOT_PROVIDED: 'Os campos obrigatórios não foram fornecidos.',
      IT_WAS_NOT_POSSIBLE_TO_REMOVE_THIS_TEAM_AS_IT_IS_LINKED_TO_AT_LEAST_ONE_FUNNEL:
        'Não foi possivel remover essa equipe pois está vinculada a pelo menos um funil.',
      ACCOUNT_DOES_NOT_EXIST: 'Conta não existe.',
      INVALID_API_TOKEN: 'Token de API inválido',
      COMPANY_NOT_FOUND: 'Empresa não encontrada.',
      INVALID_EMAIL: 'E-mail inválido',
      INVALID_DOMAIN: 'Domínio inválido',
      THERE_IS_NO_SUPPORT_FOR_THIS_WEBHOOK: 'Não há suporte para este webhook',
      INTEGRATION_WITH_INVALID_APP: 'Integração com aplicativo inválido',
      UNVERIFIED_EMAIL_WITH_AUTHENTICATION_PROVIDER: 'E-Mail não verificado com provedor de autenticação',
      USER_DOES_NOT_HAVE_PERMISSION: 'O usuário não tem permissão',
      UNIDENTIFIED_USER: 'Usuário não identificado',
      USER_DOES_NOT_HAVE_PROPER_PERMISSION: 'O usuário não tem permissão adequada',
    },
  },
};
