export const messages = {
  'en-US': {
    translations: {
      app: 'Insight Sales',
      welcome: 'Welcome to InsightSales',
      fullScreen: 'Full Screen Mode ',
      selectTheFunnelsYouWantToReceiveEmailNotificationsFor:
        'Select the funnels you want to receive email notifications for',
      selectTheFunnelsYouWantToReceiveSlackNotificationsFor:
        'Select the funnels you want to receive Slack notifications for',
      clickToSelectFunnels: 'Click to select funnels',
      noItemsSelected: 'No items selected',
      productivityDashboard: 'Pipeline Dashboard',
      lostAnalysis: 'Lost Pipeline Dashboard',
      goals: 'Goals',
      deal: 'Deal',
      Login: 'Login',
      'Select user permission': 'Select user permission',
      definitions: 'Definitions',
      Master: 'Master',
      'Commercial manager': 'Commercial manager',
      Seller: 'Seller',
      MASTER: 'Master',
      COMMERCIAL_MANAGER: 'Commercial manager',
      SELLER: 'Seller',
      'User permission': 'User permission',
      appSuccessfullyUpdated: 'APP successfully updated',
      teams: 'Teams',
      continueWithGoogle: 'Continue with Google',
      continueWithMicrosoft: 'Continue with Microsoft',
      funnels: 'Funnels',
      areNotValidEmails: 'are not valid email(s)',
      turnOnNotificationsToUpdateEmails: 'Turn on notifications to update emails',
      enterTheEmailsYouWantToReceiveNotificationsSeparatedByCommas:
        'Enter the emails you want to receive notifications separated by commas...',
      slack: 'Slack',
      efforts: 'Efforts',
      profile: 'Profile',
      notifications: 'Notifications',
      connectedTo: 'Connected to',
      connectTo: 'Connect to',
      leads: 'Leads',
      lead: 'Lead',
      myProfile: 'My Profile',
      signOut: 'Sign Out',
      settings: 'Settings',
      mySettings: 'My Settings',
      closeMenu: 'Close Menu',
      close: 'Close',
      Users: 'Users',
      'New user': 'New user',
      currencyAcronym: 'US$',
      comparePeriods: 'Compare Periods',
      compareFunnels: 'Compare Funnels',
      compareMembers: 'Compare Team Members',
      noLostFunnel: 'Funnel with no lost business',
      leadsWithLost: 'Leads with lost',
      exportCsvData: 'Export CSV data',
      exportPeriod1Data: 'Export period 1 data',
      exportPeriod2Data: 'Export period 2 data',
      weekly: 'Weekly',
      monthly: 'Monthly',
      week: 'Week',
      unlimited: 'Unlimited',
      allMembers: 'Salespeople (all members)',
      selectAFunnel: 'Select a Funnel',
      selectAMember: 'Select a Salespeople',
      funnelWithoutRegistration: 'No funnel registered',
      filterByTeamAll: 'Filter by team (all)',
      filterByStageAll: 'Step Filter (All)',
      leadsWasImportedToTheFunnel: `lead's was imported to the funnel`,
      importedLeads: `Imported: {{importedLeads}} lead's`,
      dateFilterOff: 'Data Filter (Off)',
      today: 'Today',
      yesterday: 'Yesterday',
      thisWeek: 'This Week',
      lastWeek: 'Last Week',
      thisMonth: 'This Month',
      lastMonth: 'Last Month',
      thisQuarter: 'This Quarter',
      lastTrimester: 'Last Quarter',
      thisYear: 'This Year',
      lastYear: 'Last Year',
      raceDate: 'Last Days',
      customDateRange: 'Custom Date Rage',
      last7days: 'Last 7 Days',
      last14days: 'Last 14 Days',
      last30days: 'Last 30 Days',
      last60days: 'Last 60 Days',
      last90days: 'Last 90 Days',
      last365days: 'Last 365 Days',
      initialData: 'Initial Date',
      finalDate: 'Final Date',
      selectAnImage: 'Select an Image',
      selectAnIntegrationType: 'Select an Integration Type',
      new: 'New',
      saving: 'Saving...',
      toSave: 'Save',
      normal: 'Normal',
      addInBatch: 'Add In Batch',
      template: 'template',
      fillInThe: 'Fill In',
      selectFile: 'Select File',
      choose: 'Choose',
      preSales: 'Pre Sales',
      sales: 'Sales',
      'pré-vendas': 'Pre Sales',
      vendas: 'Sales',
      theFileMustBeInCSVFormat: 'The File Must Be In CSV Format',
      exportInCSVAndAddBelow: 'Export In CSV and Add Below',
      addMember: 'Add Member',
      editMember: 'Edit Member',
      memberName: 'Member Name',
      memberEmail: 'Member Email',
      zone: 'Zone',
      office: 'Role',
      editTeam: 'Edit Team',
      newTeam: 'New Team',
      teamName: 'Team Name',
      nameOfResponsible: 'Name of Responsible',
      responsibleEmail: 'Responsible Email',
      setTimeZoneAndCurrency: 'Set time zone and currency',
      definitionsWereUpdatedSuccessfully: 'Definitions were updated successfully!',
      selectAZone: 'Select a zone',
      selectARegion: 'Select a region',
      selectACurrency: 'Select a currency',
      selectYourLanguage: 'Select your language',
      country: 'Country',
      language: 'Language',
      currency: 'Currency',
      continent: 'Continent',
      region: 'Region',
      notes: 'Notes',
      meeting: 'Meeting',
      call: 'Call',
      email: 'E-Mail',
      password: 'Password',
      forcePassword: 'Forgot My Password',
      access: 'Sign In',
      signUpForInsightSales: 'Sign Up',
      accessMyAccount: 'Access My Account',
      register: 'Register',
      company: 'Company',
      lostIn: 'Lost in',
      name: 'Name',
      redefinePassword: 'Redefine Password',
      toSend: 'Send',
      change: 'Change',
      add: 'Add',
      profilePicture: 'Profile Picture',
      chooseAnImageThatIs: 'Choose An Image That Is',
      or: 'Or',
      occupation: 'Role',
      saveEditions: 'Save',
      confirmThePassword: 'Confirm The Password',
      newPassword: 'New Password',
      currentPassword: 'Current Password',
      changePassword: 'Change Password',
      passwordChangedSuccessfully: 'Password Changed Successfully!',
      telephone: 'Telephone',
      personalData: 'Persona Data',
      tokenAPI: 'API Token',
      generateToken: 'Generate Token',
      changeToken: 'Change Token',
      addIntegration: 'Add Integration',
      integration: 'Integration',
      integrations: 'Integrations',
      considerFrozen: 'Considerer Frozen',
      editIntegration: 'Edit Integration',
      newIntegration: 'New Integration',
      options: 'Options',
      importOldData: 'Import Old Data',
      integrationAccount: 'Integration Account',
      funnel: 'Funnel',
      status: 'Status',
      team: 'Team',
      crm: 'CRM',
      funnelInCRM: 'Funnel In CRM',
      integrationFunnel: 'Integration Funnel',
      stages: 'Stages',
      line: 'Line',
      comeBack: 'Come Back',
      tryAgain: 'Try Again',
      integrateAccounts: 'Integrate Accounts',
      addAccount: 'Add Account',
      toConnect: 'Connect',
      totalGoal: 'Total Goal',
      goal: 'Goal',
      leadRecipe: 'Lead Revenue',
      MQLRecipe: 'MQL Revenue',
      SQLRecipe: 'SQL Revenue',
      opportunityRevenue: 'Opportunity Revenue',
      newCustomersRevenue: 'New Customers Revenue',
      updated: 'Updated',
      in: 'in',
      at: 'at',
      exitFullScreenMode: 'Exit Full Screen Mode',
      reached: 'Achieved',
      conversion: 'CONVERSION',
      opportunities: 'Opportunities',
      customers: 'Customers',
      client: 'Clients',
      mql: 'MQL',
      sql: 'SQL',
      lost: 'Lost',
      opportunity: 'Opportunity',
      accountName: 'Account Name (url name)',
      churn: 'Churn',
      newCustomers: 'New Customers',
      totalAverageTicket: 'Total Average Ticket',
      averageMRRTicket: 'Average Ticket MRR',
      lostRevenueInMQL: 'Lost Revenue In MQL',
      leadOpenRevenue: 'Lead open revenue',
      MQLOpenRevenue: 'MQL Open Revenue',
      lostRevenueInSQL: 'Lost Revenue In SQL',
      openSQLRecipe: 'SQL Open Revenue',
      lostRevenueInOpportunity: 'Lost Revenue In Opportunity',
      openRevenueOpportunity: 'Opportunity Open Revenue',
      MRRRevenue: 'MRR Revenue',
      salesCycle: 'Sales Cycle',
      deleteTeam: 'Are you sure you want to delete this team?',
      deleteMember: 'Are you sure you want to delete this salesperson?',
      memberSuccessfullyChanged: 'Salesperson Successfully Changed!',
      successfullyCreatedMembers: 'Salesperson Successfully Created!',
      addMoreThan5members: 'To add more than 5 salespersons, go to',
      successfullyEditedTeam: 'Successfully edited team!',
      newTeamSuccessfullyAdded: 'New team successfully added!',
      passwordResetSuccessfully: 'Password Reset Successfully!',
      theUploadedImageIsTooSmallMustHaveAtLeast: 'The uploaded image is too small. Must have at least',
      theUploadedImageIsTooBigShouldntHaveMoreTo: 'The uploaded imagem is too big. The maximum size is',
      thereWasAProblemLoadingTheImage: 'There was a problem loading the image',
      youNeedToUploadAFileOfType: 'You need to upload the following file type',
      theFileSizeMustBeLessThan0Score5MB: 'The file size must be less than 0.5MB.',
      profileSuccessfullyChanged: 'Profile Successfully Changed!',
      areYouSureYouWantToDeleteThisIntegration: 'Are you sure you want to delete this integration?',
      chooseAnIntegrationType: 'Choose an integration type.',
      chooseAFunnel: 'Choose a Funnel.',
      chooseAnIntegrationFunnel: 'Choose an integration funnel.',
      chooseAtLeastOneStageOf: 'Choose at least one stage of',
      integrationUpdatedSuccessfully: 'Integration Update Successfully!',
      integrationCreatedSuccessfully: 'Integration Created Successfully!',
      integrationPerformedSuccessfully: 'Integration Built Successfully.',
      itWasNotPossibleToPerformTheIntegrationTryAgainLater:
        'It was not possible to build the integration. Try again later.',
      areYouSureYouWantToDeleteThisAccount: 'Are you sure you want do delete this account?',
      successfullyAddedGoal: 'Goal Added!',
      targetUpdatedSuccessfully: 'Goal Updated!',
      wantToSaveWithoutSelectingAllMembers: 'Want to save withtou selecting all members?',
      areYouSureYouWantToDeleteThisFunnel: 'Are you sure you want to delet this funnel?',
      cancel: 'Cancel',
      Cancel: 'Cancel',
      editOpportunity: 'Edit Opportunity',
      newOpportunity: 'New Opportunity',
      opportunitySuccessfullyEdited: 'Opportunity Successfylly Edited!',
      newOpportunitySuccessfullyAdded: 'New Opportunity Successfuly Added!',
      companyCannotBeBlank: 'It is necessary to fill in the company name',
      addAtLeast1Responsible: 'Add at least 1 deal owner',
      fillInThoseResponsible: 'Add the deal owners',
      addAtLeast1PersonResponsiblePerInternship: 'Add at least 1 deal owner per stage',
      responsibles: 'Owner',
      value: 'Value',
      leadIn: 'Lead in',
      leadResponsibles: 'Lead responsibles',
      mqlIn: 'MQL in',
      mqlResponsibles: 'MQL responsibles',
      sqlIn: 'SQL in',
      sqlResponsibles: 'SQL responsibles',
      opportunityIn: 'Opportunity in',
      opportunityResponsibles: 'Opportunity responsibles',
      dealIn: 'Deal in',
      dealResponsibles: 'Deal responsibles',
      addOpportunity: 'Add Opportunity',
      youWillReceivePasswordResetInstructionsInYourEmail: 'You will receive password reset instructions in your email.',
      opsThereWasAProblem: 'Ops! There was a problem.',
      sessionExpired: 'Session Expired',
      updateEvery5minutes: 'Update Every (5 minutos)',
      minutes10: '10 minutes',
      minutes30: '30 minutes',
      connectedAccounts: 'Connected Accounts',
      selectAnOption: 'Select An Option',
      selectAFilter: 'Select A Filter',
      Title: 'Title',
      Goal: 'Goal',
      Reach: 'Reach',
      Conquest: 'Conquest',
      Indicator: 'Indicator',
      yes: 'Yes',
      not: 'No',
      openMenu: 'Open Menu',
      appIntegrationSuccessfullyDeleted: 'App integration successfully deleted',
      all: 'All',
      dashboards: 'Dashboards',
      period: 'Period',
      period1: 'Period 1',
      period2: 'Period 2',
      opportunitySuccessfullyRemoved: 'Opportunity Successfully Removed!',
      enterYourSearchHere: 'Enter Your Search Here',
      cardLimit20: 'Card Limit (20)',
      cards50: '50 cards',
      cards100: '100 cards',
      ok: 'OK',
      noIntegration: 'No Integration',
      selectATeam: 'Select A Team',
      inactive: 'Inactive',
      active: 'Active',
      typeHere: 'Type Here',
      funnelSuccessfullyChanged: 'Funnel Successfully Changed!',
      funnelCreatedSuccessfully: 'Funnel Created Successfully!',
      'User created successfully': 'User created successfully',
      'User updated successfully': 'User updated successfully',
      'User successfully deleted': 'User successfully deleted',
      Close: 'Close',
      toEdit: 'Edit',
      Copied: 'Copied',
      delete: 'Delete',
      Create: 'Create',
      Update: 'Update',
      Optional: 'Optional',
      opsSomethingWrong: 'Ops! Something went wrong...',
      criticalErrorMessagePart1: 'We are sorry for the inconvenience. Please, contact support.',
      criticalErrorMessagePart2: 'so we can help',
      goToHomePage: 'Go To Homepage',
      yourPersonalAPIToken: 'Your Personal API Token',
      members: 'Members',
      member: 'Member',
      openingDate: 'Opening Date',
      nowAllYouHaveToDoIsSendYourAPIToken: 'Now all you have to do is send your API token',
      editToken: 'Edit Token',
      openedIn: 'Opened in',
      deleteAccount: 'Delete account',
      tokenNotTested: 'Token not tested',
      registeredToken: 'Registered token',
      tokenNotSentOrInvalid: 'Token not sent or invalid',
      testAPIToken: 'Test API Token',
      INTERNAL_SERVER_ERROR: 'Internal Server Error.',
      UNABLE_TO_CREATE_A_GOAL_FOR_PAST_MONTHS: 'Unable to Create a Goal for Past Months',
      MEMBER_STAGES_ARE_INVALID: 'Member stages are invalid',
      FUNNEL_NOT_FOUND: 'Funnel not found.',
      TARGET_ALREADY_EXISTS_FOR_THIS_FUNNEL: 'Goal already exists for this funnel',
      MEMBER_NOT_FOUND: 'Member not found',
      STAGES_CANNOT_BE_EMPTY: 'Stages cannot be empty',
      STAGES_MUST_BE_MQL_SQL_OPPORTUNITY_OR_CUSTOMERS: 'Funnel stages must be MQL, SQL, Opportunity or Clients',
      THE_STAGES_ARE_INVALID: 'The stages are invalid',
      INVALID_ARGUMENT_TYPE: 'Invalid argument type.',
      INVALID_OBJECT_TYPE: 'Invalid object type.',
      NO_ACCESS_TOKEN_PROVIDED: 'No access token provided',
      THE_TOKEN_SIGNATURE_HAS_EXPIRED: 'Token signature has expired.',
      THIS_EMAIL_ALREADY_HAS_AN_ACCOUNT: 'This email already has an account.',
      EMAIL_NOT_ENTERED: 'Inform email.',
      DISABLED_COMPANY_IN_CASE_OF_DOUBT_CONTACT_OUR_SUPPORT:
        'Company disabled. In case of questions contact our support.',
      INCORRECT_EMAIL_OR_PASSWORD: 'Email or Password are incorrect.',
      PASSWORD_AND_CONFIRMATION_PASSWORD_DO_NOT_MATCH: 'Password and Confirmation Password do not match.',
      USER_NOT_FOUND: 'User not found.',
      INCORRECT_CURRENT_PASSWORD: 'Current password incorrect.',
      EMAIL_IS_REQUIRED: 'Email is required.',
      NO_USERS_FOUND_WITH_THIS_EMAIL_ADDRESS: 'No users found with this email address.',
      NO_USERS_FOUND: 'No users found.',
      INVALID_TOKEN: 'Invalid token.',
      INTEGRATION_NOT_FOUND: 'Integration not found.',
      THE_STAGES_MUST_BE: 'The stages must be',
      OR: 'or',
      THE_STAGES_MUST_BE_NAMES_OR_NAMES: '$t(THE_STAGES_MUST_BE) {{orJoin}}',
      INTEGRATION_ALREADY_EXISTS: 'Integration already exists.',
      ACCOUNT_NOT_FOUND: 'Account not found.',
      INTEGRATION_FUNNELS_ARE_DUPLICATED: 'Integration funnels are duplicated!',
      LINE: 'Line',
      LINE_N_FUNNEL_NOT_FOUND: '$t(LINE) {{count}} - $t(FUNNEL_NOT_FOUND)',
      LINE_N_INTEGRATION_ALREADY_EXISTS: '$t(LINE) {{line}} - $t(INTEGRATION_ALREADY_EXISTS)',
      LINE_N_INTEGRATION_ALREADY_EXISTS_STAGE_VALIDATION:
        '$t(LINE) {{line}} - $t(INTEGRATION_ALREADY_EXISTS) {{orJoin}}',
      YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN: 'You have reached the limit of your plan.',
      YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN_TO_ADD_MORE_SELLERS_CONTACT_OUR_CS_TEAM_IN_CHAT:
        '$t(YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN) To add more salespeople, contact our CS team.',
      YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN_To_ADD_MORE_FUNNELS_PLEASE_CHAT_WITH_OUR_CSS_TEAM:
        '$t(YOU_HAVE_REACHED_THE_LIMIT_IN_THE_CONTRACTED_PLAN) To add more funnels, contact our CS team.',
      TEAM_NOT_FOUND: 'Team not found',
      LINE_TEAM_NOT_FOUND: 'Team not found',
      INVALID_INTEGRATION_TYPE: 'Invalid integration type.',
      API_TOKEN_IS_INVALID: 'API token is invalid!',
      GOAL_NOT_FOUND: 'Goal not found',
      THE_STEP_MUST_BE_EQUAL_TO_MQL_SQL_OPPORTUNITY_OR_DEAL:
        'The step must be equal to `mql`,` sql`, `opportunity` or` client`',
      THE_AT_IS_NOT_A_VALID_DATE: 'The `at` is not a a valid date',
      OPPORTUNITY_NOT_FOUND: 'Opportunity not found',
      SIGNATURE_NOT_FOUND: 'Signature not found.',
      SIGNATURE_VERSION_IS_INVALID: 'Signature version is invalid.',
      UNAUTHORIZED: 'Unauthorized',
      TEAM_MEMBER_NOT_FOUND: 'Team member not found',
      INVALID_CONNECTION_TYPE: 'Invalid connection type.',
      FILL_IN_THE_REQUIRED_FIELDS: 'Fill in the required fields.',
      INVALID_STEP_PROVIDED_MUST_BE_MQL_SQL_OR_DEAL: 'Invalid step. Must be mql, sql or deal.',
      THE_FUNNEL_LEAD_IS_ALREADY_IN_THIS_STEP: 'The lead is already in this step.',
      FIELDS_REQUIRED_WAS_NOT_PROVIDED: 'Required fiels were not provided.',
      IT_WAS_NOT_POSSIBLE_TO_REMOVE_THIS_TEAM_AS_IT_IS_LINKED_TO_AT_LEAST_ONE_FUNNEL:
        'It was not possible to remove this team as it is linked to at least one funnel.',
      ACCOUNT_DOES_NOT_EXIST: 'Account does not exist',
      INVALID_API_TOKEN: 'Invalid API token',
      COMPANY_NOT_FOUND: 'Company not found',
      INVALID_EMAIL: 'Invalid email',
      INVALID_DOMAIN: 'Invalid domain',
      THERE_IS_NO_SUPPORT_FOR_THIS_WEBHOOK: 'There is no support for this webhook',
      INTEGRATION_WITH_INVALID_APP: 'Integration with invalid application',
      UNVERIFIED_EMAIL_WITH_AUTHENTICATION_PROVIDER: 'E-Mail not verified with authentication provider',
      USER_DOES_NOT_HAVE_PERMISSION: 'User does not have permission',
      UNIDENTIFIED_USER: 'Unidentified user',
      USER_DOES_NOT_HAVE_PROPER_PERMISSION: 'User does not have proper permission',
    },
  },
};
